export default {
    HTTP_OK : 200,
    HTTP_CREATED : 201,
    HTTP_ACCEPTED : 202,
    HTTP_NO_CONTENT : 204,
    HTTP_RESET_CONTENT : 205,
    HTTP_PARTIAL_CONTENT : 206,
    HTTP_MOVED_PERMANENTLY : 301,
    HTTP_FOUND : 302,
    HTTP_NOT_MODIFIED : 304,
    HTTP_TEMPORARY_REDIRECT : 307,
    HTTP_BAD_REQUEST : 400,
    HTTP_UNAUTHORIZED : 401,
    HTTP_NOT_FOUND : 404,
    HTTP_METHOD_NOT_ALLOWED : 405,
    HTTP_NOT_ACCEPTABLE : 406,
    HTTP_INTERNAL_SERVER_ERROR : 500,
    HTTP_BAD_GATEWAY : 502,
    HTTP_SERVICE_UNAVAILABLE : 503
}